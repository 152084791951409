﻿//1. Import all dependencies
import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { Configuration } from '../../../app.constant';
import { Observable } from 'rxjs';
import 'rxjs/Rx';
import { ServiceResponse } from '../../../core/models/service-response.model';
import { NewServiceResponse } from 'app/core/models/new-service-response.model';
import { HttpClient } from '@angular/common/http';

//2. The service class
@Injectable()
export class AccountService {
    //3. The local private variable for  storing the URL of the REST API.   
    private getMerchantInfoUrl: string;
    private tempImageUploadUrl: string;
    private updateMerchantInfoUrl: string;
    private updateMerchantPasswordInfoUrl: string;
    private getProductDropDownUrl: string;
    private dropDownData: any;
    private updateLocation: string;
    
    constructor(private _http: HttpClient,
        private _configuration: Configuration) {

        this.tempImageUploadUrl = `${_configuration.WebApi}api/merchant/SaveAKAttachmentBytes/`;
        this.getMerchantInfoUrl = `${_configuration.WebApi}api/merchant/info`;
        this.updateMerchantInfoUrl = `${_configuration.WebApi}api/user/API-registration`;
        this.updateMerchantPasswordInfoUrl = `${_configuration.WebApi}api/user/ChangePassword`;
        this.getProductDropDownUrl = `${_configuration.WebApi}api/Store/GetAllStoreDropdowns`;
        this.updateLocation = `${_configuration.WebApi}api/user/Hidemylocation`;
    }

    getMerchantInfo(merchantId: string): Observable<ServiceResponse> {
        return this._http.get<ServiceResponse>(this.getMerchantInfoUrl + "/" + merchantId);
    }

    updateMerchantAccount(merchantModel: any): Observable<NewServiceResponse> {
        return this._http.post<NewServiceResponse>(this.updateMerchantInfoUrl, merchantModel);
    }

    getProductDropDownData(): Observable<NewServiceResponse> {
        return this
            ._http.get<NewServiceResponse>(this.getProductDropDownUrl)
            .do((res) => {
                if (res.StatusCode == 200) {
                    this.dropDownData = res.Data;
                }
            });
    }

    uploadImage(imageBytes: string): Observable<ServiceResponse> {

        let param: any = {};
        param.ImageBytes = imageBytes
        return this._http.post<ServiceResponse>(this.tempImageUploadUrl, param);
    }

    updateMerchantPasswordInfo(userModel: any): Observable<ServiceResponse> {
        return this
            ._http.post<ServiceResponse>(this.updateMerchantPasswordInfoUrl, userModel);
    }

    hidemylocation(locationStatus): Observable<ServiceResponse> {
        return this._http.post<ServiceResponse>(this.updateLocation, locationStatus);
    }
}